import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import "./tailwind.css";
import { useEffect } from "react";
import { usePosthog } from "./hooks/posthog";
import { json, LoaderFunctionArgs } from "@remix-run/node";

export type RootLoaderData = {
  host: string;
  baseUrl: string;
  appTitle: string;
}

export async function loader({ request }: LoaderFunctionArgs): Promise<Response> {
  const host = request.headers.get("host")
  let appTitle = ''
  if (host?.includes("findpowerpeople")) {
    appTitle = "FindPowerPeople"
  } else if (host?.includes("banter")) {
    appTitle = "Banter"
  } else if (host?.includes("bromii")) {
    appTitle = "Bromii"
  } else {
    appTitle = "FindPowerPeople"
  }

  return json({
    host: host,
    appTitle: appTitle,
    baseUrl: host?.includes("localhost") ? `http://${host}` : `https://${host}`,
  })
}

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  if (isRouteErrorResponse(error)) {
    return <h1>{error.status}</h1>;
  }
  return <h1>Unknown Error</h1>;
};


export default function App() {
  const location = useLocation()
  // Hydration issues if we don't explicitly initialize it in a useeffect
  const postHogClient = usePosthog();

  useEffect(() => {
    postHogClient?.capture("$pageview")
  }, [postHogClient, location])


  return <Outlet />;
}
