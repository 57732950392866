import { MetaFunction, useActionData, useFetcher, useSubmit, useRouteLoaderData, useNavigate, useLocation } from "@remix-run/react";
import { Button } from "~/components/ui/button";
import type { RootLoaderData } from "~/root";
import { IconFooter } from "~/components/layout/IconFooter";
import { useState } from "react";

export const meta: MetaFunction<RootLoaderData> = ({ matches }) => {
    const baseUrl = matches[0].data?.baseUrl;
    const appTitle = matches[0].data?.appTitle;

    return [
        { title: `${appTitle} - Connect with the Most Relevant People at Professional Events` },
        { name: "description", content: "Discover and connect with relevant people at professional events effortlessly with our SaaS tool. Enhance your networking and sales experiences and make meaningful connections with ease. Try it now!" },
        { name: "keywords", content: "Professional events, SDR tool, event networking, find relevant people, event connections, professional networking software, sales research tool" },
        { property: "og:title", content: `${appTitle} - Connect with the Most Relevant People at Professional Events` },
        { property: "og:description", content: "Discover and connect with relevant people at professional events effortlessly with our SaaS tool. Enhance your networking and sales experiences and make meaningful connections with ease. Try it now!" },
        {
            property: "og:image", content: `https://953697314.imgix.net/landing/people.png?auto=format,
        enhance` },
        { property: "og:url", content: baseUrl },
        { property: "og:type", content: "website" },
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: `${appTitle} - Connect with the Most Relevant People at Professional Events` },
        { name: "twitter:description", content: "Discover and connect with relevant people at professional events effortlessly with our SaaS tool. Enhance your networking and sales experiences and make meaningful connections with ease. Try it now!" },
        { name: "twitter:image", content: "https://953697314.imgix.net/landing/people.png?auto=format,enhance" },
    ];
};

interface ActionData {
    message?: string;
    error?: string;
}

async function sendDiscordMessage(email: string) {
    try {
        await fetch('https://discord.com/api/webhooks/1271653823588597820/ReQBkvN4vWASk6VLiHh75zdVVtTOLlr3M1UVNmWBNW2Y6vzK0Sd_djnCvVsCSbTPRC9E', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                content: `${email} signed up for waitlist`
            }),
        })
    } catch (error) {
        console.error('Error sending discord message:', error)
    }
}

export default function About() {
    const { appTitle } = useRouteLoaderData<RootLoaderData>("root");
    const [isTransitioning, setIsTransitioning] = useState(false);
    const fetcher = useFetcher();
    const submit = useSubmit();
    const actionData = useActionData<ActionData>();
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 pt-6 pb-32 pr-4 animate-in fade-in duration-1000 overflow-y-scroll flex-1 animate-fade-in data-[transitioning=true]:animate-fade-out"
                data-transitioning={isTransitioning}>
                <div className="max-w-2xl mx-auto px-4 py-8">
                    <h1 className="text-4xl font-bold mb-8 hidden">About Us</h1>
                    <div className="flex flex-col w-full items-center">
                        <div className="flex flex-col max-w-[500px] mt-5 ms-4 ml-1/2 mr-4">
                            <h1 className="text-3xl underline text-center mb-4">
                                Never waste time at professional events again.
                            </h1>
                            <p className="text-center">
                                Ever been to a happy hour, tech talk, or conference event, and had no clue who's standing in the room with you?
                                <br />
                                <br />
                                {`With ${appTitle}, you get a directory of attendee profiles, to help you find & chat with the most relevant people.`}

                            </p>
                            <p className="font-bold text-center mb-2">
                                Don't waste time. Don't miss opportunities.
                            </p>

                            <div className="flex flex-row justify-center mb-14 h-10">
                                <div className="flex items-center space-x-4">
                                    <a href="/login">
                                        <Button variant={"landing"}>Signup | Login</Button>
                                    </a>
                                </div>
                            </div>


                            <h3 className="text-left mb-2">How It Works:</h3>
                            <ol className="list-decimal list-inside mb-6">
                                <li><strong>Upload the Guests:</strong> Provide us with the event page html, or a list of names.</li>
                                <li><strong>We Enrich:</strong> We create professional profiles with tags based on what matters to you.</li>
                                <li><strong>Search the Guests:</strong> Use a google sheet to research the guests, or use our mobile web app to identify people during the event. </li>
                            </ol>

                            <h3 className="text-left mb-2">Benefits:</h3>
                            <ul className="list-disc list-inside mb-6">
                                <li>Understand whether the event is worth attending.</li>
                                <li>Meet at least one very relevant person at every event.</li>
                                <li>Easily find your next prospect, great new hire, or new manager.</li>
                            </ul>

                            <h3 className="text-left mb-2">Pricing:</h3>
                            <ul className="list-disc list-inside mb-6">
                                <li>Free tier: 1 credit per month</li>
                                <li>Pro tier: 10 credits for $20 per month</li>

                            </ul>
                            <p className="text-left !m-0">(1 credit per 50 people at an event)</p>


                            <img src="https://953697314.imgix.net/landing/people.png?auto=format,enhance" alt="People" className="mt-5 w-1/2" />
                        </div>
                    </div >
                </div>
            </div>

            <IconFooter
                icons={[
                    { id: 'explore', route: '/explore' },
                    { id: 'about', route: '/about' },
                    { id: 'support', route: '/support' },
                    { id: 'login', route: '/login' }
                ]}
                selectedIcon="about"
                navigate={navigate}
                location={location}
            />
        </div>
    );
}
